.login-input {
  border-color: #2250fc;
  height: 3rem;
  border-width: 1.5px;
  border-radius: 10px;
  width: 20rem;
  font-family: Montserrat;
}

.login-password-input {
  border-color: #2250fc;
  height: 3rem;
  border-width: 1.5px;
  border-radius: 10px;
  width: 20rem;
  font-family: Montserrat;
}

.login-button {
  border-radius: 12px;
  background: #2250fc !important;
  color: white !important;
  width: 20rem;
  height: 3rem;
}

.mfa-button {
  border-radius: 12px;
  background: #2250fc !important;
  color: white !important;
}

.login-button:hover {
  background: #007bff !important;
}

.login-page-download-button {
  border-radius: 12px;
  background: #179a3a !important;
  color: white !important;
  width: 20rem;
  height: 3rem;
}

.login-page-download-button:hover {
  background: #39b95b !important;
}
.forget-password-button:hover {
  color: black !important;
}

.create-an-account-button {
  margin-right: 10px;
  border-width: 2px;
  border-radius: 13px;
  width: 20rem;
  background: white !important;
  color: #2250fc !important;
  transition: background-color 0.3s, color 0.3s;
}

.create-an-account-button:hover {
  background: #2250fc !important;
  color: white !important;
  border-width: 2px;
  border-radius: 13px;
}

.login-image {
  max-width: 100%;
  height: auto;
}

.show-password-button {
  border: none;
  background: rgba(0, 0, 0, 0);
  color: rgb(128, 128, 128);
  font-size: 23px;
  cursor: pointer;
  position: absolute;
  margin-left: 275px;
}

@media (max-width: 414px) {
  .login-image {
    display: none;
  }
  .login-password-input {
    width: 288px;
  }
}

@media (max-width: 767px) {
  .login-image {
    display: none;
  }
  .login-password-input {
    margin: 0;
  }
}

@media (max-width: 820px) {
  .login-password-input {
    width: 320px !important;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .login-image {
    width: 50%;
  }
  .login-password-input {
    margin: 0 !important;
    margin-left: 0px !important;
  }
}
